<template>
  <div v-if="!loading">
    <b-form-select class="selector" v-model="newyear" :options="year" size="sm" disabled></b-form-select>
    <table class="table table-sm">
      <thead>
        <tr>
          <th width="5%" style="text-align:left;" scope="col"></th>
          <th width="15%" scope="col"></th>
          <th width="40%" scope="col">Player</th>
          <th width="8%" class="stats" scope="col">L</th>
          <th width="8%" class="stats" scope="col">S</th>
          <th width="8%" class="stats" scope="col">T</th>
          <th width="8%" style="text-align:right;" scope="col">P</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(profile, index) in leaderboard" :key="profile.id" @click="lookup_profile(profile.id)">
          <!-- <td v-if="profile.motion > 0" style="vertical-align: middle; color: green;"><span>▲</span>{{profile.motion}}</td> -->
          <!-- <td v-else-if="profile.motion < 0" style="vertical-align: middle; color: red;"><span>▼</span>{{profile.motion.toString().substring(1)}}</td> -->
          <td v-if="profile.motion > 0" style="vertical-align: middle; color: green;">&#8593;{{profile.motion}}</td>
          <td v-else-if="profile.motion < 0" style="vertical-align: middle; color: red;">&#8595;{{profile.motion.toString().substring(1)}}</td>
          <td v-else></td>
          
          <td><img v-if="profile.picture" v-bind:src="'/server/1900eab6c028483d7126599ee6f50de0d27907b5c65fa90524580b4b0f9852b/'+profile.picture" class="picture" :style="{border: '2px solid' + profile.color}" /></td>
          <td scope="row" :style="{color: profile.color}">
            <span v-if="index === 0">&#129351;</span>
            <span v-if="index === 1">&#129352;</span>
            <span v-if="index === 2">&#129353;</span>
            <span v-if="index === (leaderboard.length-1)" class="wave">&#9995;</span>
            {{profile.name}}
          <br>
          <div class="progress" style="margin-top:5px;">
            <div class="progress-bar" role="progressbar" aria-label="Basic example" :style="{width: profile.accuracy +'%'}" :aria-valuenow="profile.accuracy" aria-valuemin="0" aria-valuemax="100">{{profile.accuracy}}%</div>
          </div>
          </td>
          <td class="stats" style="vertical-align: bottom; padding: .2rem;">{{profile.stats.l}}</td>
          <td class="stats" style="vertical-align: bottom; padding: .2rem;">{{profile.stats.s}}</td>
          <td class="stats" style="vertical-align: bottom; padding: .2rem;">{{profile.stats.t}}</td>
          <td style="text-align:right; vertical-align: bottom; padding: .2rem;"><span v-if='profile.currency<0' style="color:red; font-size: 12px;">&#8595;${{ (profile.currency*-1).toFixed(2) }}</span><span v-else style="color:green; font-size: 12px;">&#8593;${{ profile.currency }}</span><br>{{profile.points}}</td>
        </tr>
      </tbody>
    </table>

    <!-- <table class="table table-dark table-sm">
      <thead>
        <tr>
          <th width="5%" style="text-align:right;" scope="col"></th>
          <th width="15%" scope="col"></th>
          <th width="40%" scope="col">Player</th>
          <th width="10%" scope="col">L</th>
          <th width="10%" scope="col">S</th>
          <th width="10%" scope="col">T</th>
          <th width="10%" style="text-align:right;" scope="col">P</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="profile in profiles" :key="profile.id" @click="lookup_profile(profile.id)">
          <td style="vertical-align: center; color: green;">^1</td>
          <td><img v-if="profile.picture" v-bind:src="'/server/1900eab6c028483d7126599ee6f50de0d27907b5c65fa90524580b4b0f9852b/'+profile.picture" class="picture" :style="{border: '2px solid' + profile.color}" /></td>
          <th scope="row" :style="{color: profile.color}">{{profile.user.firstName}} {{profile.user.lastName.charAt(0)}}.<br>
          <div class="progress">
            <div class="progress-bar" role="progressbar" aria-label="Basic example" :style="{width: profile.score +'%'}" :aria-valuenow="profile.score" aria-valuemin="0" aria-valuemax="100">{{profile.score}}</div>
          </div>
          </th>
          <td style="vertical-align: bottom; padding: .2rem;">{{profile.l}}</td>
          <td style="vertical-align: bottom; padding: .2rem;">{{profile.s}}</td>
          <td style="vertical-align: bottom; padding: .2rem;">{{profile.t}}</td>
          <td style="text-align:right; vertical-align: bottom; padding: .2rem;">{{profile.score}}</td>
          
        </tr>
      </tbody>
    </table> -->
  </div>
  <div v-else>
    <div class="text-center">
      <b-spinner variant="danger" label="Loading..."></b-spinner>
    </div>
  </div>
</template>

<script>
import { api } from "@/utils/api";

export default {
  name: "Leaderboard",
  data() {
    return {
      profiles: null,
      loading: true,
      leaderboard: null,
      newyear: null,
      year: [{ value: null, text: "2024 CFB Season" }],
    };
  },
  created() {
    let self = this;
    // api(
    //   `query { profiles{ id picture l s t score user { id username firstName lastName } color } }`
    // ).then(data => {
    //   self.profiles = data.profiles.sort((a, b) => b.points - a.points);
    //   self.loading = false;
    // });
    api(
      `mutation { leaderboard { message data } }`
    ).then(data => {
      self.leaderboard = JSON.parse(data.leaderboard.data)
      self.loading = false;
    });
  },
  methods: {
    lookup_profile(id) {
      console.log(id)
      this.$router.push({ name: "Profile", params: { id: id }, });
    }
  }
};
</script>

<style scoped>
.table th {
  border: 0px solid #444;
}
.selector {
  background-color: #222;
  border-color: #303030;
  color: white;
  font-size: larger;
  margin-bottom: 10px;
}

.picture {
  width:35px;
  height:35px;
  object-fit:cover;
  border-radius:50%;
}
.stats {
  text-align: center;
}

.wave {
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;        /* Change to speed up or slow down */
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(18.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
   20% { transform: rotate(-12.0deg) }
   30% { transform: rotate(18.0deg) }
   40% { transform: rotate(-8.0deg) }
   50% { transform: rotate(12.0deg) }
   60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { transform: rotate( 0.0deg) }
}

</style>