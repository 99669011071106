<template>
  <div>
    <leaderboard />
    <hr>
    <div v-if="!loading">
      <progress-graph :labels="labels" :data="data" />
    </div>
    <div v-else>
      <div class="text-center">
        <b-spinner variant="danger" label="Loading..."></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/utils/api";

import ProgressGraph from "@/components/graphs/ProgressGraph";
import Leaderboard from "@/components/Leaderboard";

export default {
  name: "Dashboard",
  components: {
    ProgressGraph,
    Leaderboard
  },
  data() {
    return {
      labels: null,
      data: null,
      loading: true
    };
  },
  created() {
    let self = this;
    api(`mutation{ progressgraph{ code message labels data } }`).then(data => {
      self.labels = JSON.parse(data.progressgraph.labels);
      self.data = JSON.parse(data.progressgraph.data);
      self.loading = false;
    });
  }
};
</script>